import React, { useState, useRef, useEffect } from 'react'
import { motion, useMotionValue, useTransform } from 'framer-motion'

function App() {
  const [count, setCount] = useState(0)
  const constraintsRef = useRef(null)
  const [currentAngle, setCurrentAngle] = useState(0)
  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const angle = useTransform([x, y], ([latestX, latestY]) => {
    return Math.atan2(latestY, latestX) * (180 / Math.PI)
  })

  useEffect(() => {
    const unsubscribe = angle.onChange((latest) => {
      setCurrentAngle(latest)
    })
    return unsubscribe;
  }, [angle])

  const bodyStyle = {
    fontFamily: 'Inter, system-ui, Helvetica, Arial, sans-serif',
    lineHeight: 1.5,
    fontWeight: 400,
    colorScheme: 'light dark',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    maxWidth: '1280px',
    margin: '2em auto',
    padding: '2rem',
    textAlign: 'center',
    fontSynthesis: 'none',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    display: 'flex',
    flexDirection: 'column',  // Stack elements vertically
    alignItems: 'center',  // Horizontally center items
    justifyContent: 'center',  // Vertically center items
    minWidth: '320px',
    minHeight: '100vh',
  };
  const h1Style = {
    fontSize: '3.2em',
    lineHeight: 1.1,
  }

  const buttonStyle = {
    borderRadius: '8px',
    border: '1px solid transparent',
    padding: '0.6em 1.2em',
    fontSize: '1em',
    fontWeight: 500,
    fontFamily: 'inherit',
    backgroundColor: 'aliceblue',
    cursor: 'pointer',
    transition: 'border-color 0.25s',
  }

  const containerStyle = {
    width: '300px',
    height: '300px',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    overflow: 'hidden',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '30px',
  }

  const itemStyle = {
    width: '150px',
    height: '150px',
    background: 'rgb(190, 190, 190)',
    borderRadius: 'inherit',
  }

  return (
    <div style={bodyStyle}>
      <h1 style={h1Style}>Test</h1>
      <div className="card">
        <button style={buttonStyle} onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </button>
      </div>
      <motion.div ref={constraintsRef} style={containerStyle}>
        <motion.div 
          drag 
          style={{ ...itemStyle, x, y }}
          dragConstraints={constraintsRef}
        >
          <span>{currentAngle.toFixed(2)}°</span>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default App
